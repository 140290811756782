import "./rates.scss";
import dashboard_trade_icon from "../../assets/dashboard_trade_icon.svg";
import dashboard_deposit_icon from "../../assets/dashboard_deposit_icon.svg";
import dashboard_withdraw_icon from "../../assets/dashboard_withdraw_icon.svg";
import Header from "../../header/header";
import { useAuth } from "../../providers/AuthProvider";
import { useTransactDispatch } from "../transact/providers/TransactProvider";
import { useFeatureFlags } from "../../providers/FeatureFlags/FeatureFlagsProvider";
import Transact from "../transact/transact";
import ReactSelect from "react-select";
import { useRates } from "../../providers/Rates/RatesProvider";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import _ from "lodash";
import Big from "big.js";
import { formatAmount } from "../../common/formatAmount";

const reactSelectStyleRates = {
	control: (baseStyles, state) => ({
		...baseStyles,
		fontSize: ".9rem",
		fontWeight: "600",
		padding: ".2rem",
		borderRadius: "8px",
		width: "9rem",
		color: "#172c50",
		backgroundColor: "#ffffff",
		borderColor: "#3E9CF3",
	}),
};

const Rates = () => {
	const { isLoggedIn } = useAuth();
	const { isLoadingRates, currencies, rates, getRate, pairprices, isLoadingPairPrices } = useRates();
	const transactDispatch = useTransactDispatch();
	const { isFeatureFlagEnabled } = useFeatureFlags();

	const [ratesState, setRatesState] = useState({
		baseCurrency: "USD",
		ratesLoaded: false,
	});

	const baseCurrencyChanged = (code) => {
		const newRatesState = _.cloneDeep(ratesState);
		newRatesState.baseCurrency = code;
		setRatesState(newRatesState);
	};

	const isLoading = () => {
		return isLoadingPairPrices || isLoadingRates || _.isEmpty(rates) || _.isEmpty(pairprices);
	};

	const getBuyPrice = (currency) => {
		const baseCurrencyCode = ratesState.baseCurrency;
		const currencyCode = currency.code;

		if (baseCurrencyCode == currencyCode) {
			return `1 ${baseCurrencyCode}`;
		}

		const possiblePairs = [`${baseCurrencyCode}/${currencyCode}`, `${currencyCode}/${baseCurrencyCode}`];
		const pairprice = _.find(pairprices, { pair: possiblePairs[0] }) || _.find(pairprices, { pair: possiblePairs[1] });

		let refPrice = 0;
		try {
			refPrice = getRate({
				rates,
				from: baseCurrencyCode,
				to: currencyCode,
			});
		} catch (err) {}

		let markdown = 0;
		try {
			markdown = pairprice.markdown;
		} catch (err) {}

		let buyPrice = 0;
		try {
			buyPrice = Big(refPrice)
				.sub(Big(refPrice).mul(Big(markdown).div(Big(100))))
				.toString();
		} catch (err) {}

		return (
			<div className="buyPrice">
				<span className="help_text">1 {baseCurrencyCode} = </span> {formatAmount(buyPrice)} {currencyCode}
			</div>
		);
	};

	const getSellPrice = (currency) => {
		const baseCurrencyCode = ratesState.baseCurrency;
		const currencyCode = currency.code;

		if (baseCurrencyCode == currencyCode) {
			return `1 ${baseCurrencyCode}`;
		}

		const possiblePairs = [`${baseCurrencyCode}/${currencyCode}`, `${currencyCode}/${baseCurrencyCode}`];
		const pairprice = _.find(pairprices, { pair: possiblePairs[0] }) || _.find(pairprices, { pair: possiblePairs[1] });

		let refPrice = 0;
		try {
			refPrice = getRate({
				rates,
				from: baseCurrencyCode,
				to: currencyCode,
			});
		} catch (err) {}

		let markup = 0;
		try {
			markup = pairprice.markup;
		} catch (err) {}

		let sellPrice = 0;
		try {
			sellPrice = Big(refPrice)
				.add(Big(refPrice).mul(Big(markup).div(Big(100))))
				.toString();
		} catch (err) {}

		return (
			<div className="sellPrice">
				<span className="help_text">1 {baseCurrencyCode} = </span> {formatAmount(sellPrice)} {currencyCode}
			</div>
		);
	};

	return (
		<>
			{!isLoggedIn && <Header />}
			<div id="rates_page">
				<div className="heading">
					<div className="title">Rates</div>
				</div>

				{isLoading() && (
					<div className="rates_loader_container">
						Loading rates...
						<Loader2 className="rates_loader" />
					</div>
				)}

				{!isLoading() && (
					<>
						<div className="rates_controls">
							<div className="lhs">
								<div className="default_currency">
									<div className="label">Base currency</div>
									<div className="inputs">
										<ReactSelect
											defaultValue={{
												value: "USD",
												label: "USD",
											}}
											onChange={(evt) => baseCurrencyChanged(evt.value)}
											styles={reactSelectStyleRates}
											options={currencies.map((c) => ({
												value: c.code,
												label: c.code,
											}))}></ReactSelect>
									</div>
								</div>

								{isLoggedIn && false && (
									<div className="topboar_currencies">
										<div className="label">Pick top bar currencies</div>
										<div className="inputs">
											<ReactSelect styles={reactSelectStyleRates}></ReactSelect>
										</div>
									</div>
								)}
							</div>
							<div className="rhs">
								{isLoggedIn && (
									<div className="transact_controls">
										{/* Transact Buttons */}
										<button
											onClick={() => {
												transactDispatch({ type: "transact" });
											}}
											className="control trade"
											popovertarget="transact_popover"
											popovertargetaction="show">
											<div className="control_icon">
												<img src={dashboard_trade_icon} alt="trade icon" />
											</div>
											<div className="control_title">TRADE</div>
										</button>
										{isFeatureFlagEnabled("deposits") && (
											<button className="control deposit">
												<div className="control_icon">
													<img src={dashboard_deposit_icon} alt="deposit icon" />
												</div>
												<div className="control_title">DEPOSIT</div>
											</button>
										)}
										{isFeatureFlagEnabled("withdrawals") && (
											<button className="control withdraw">
												<div className="control_icon">
													<img src={dashboard_withdraw_icon} alt="withdraw icon" />
												</div>
												<div className="control_title">WITHDRAW</div>
											</button>
										)}
										<div id="transact_popover" popover="auto">
											<Transact />
										</div>
									</div>
								)}
							</div>
						</div>

						<table className="rates_table">
							<thead>
								<tr>
									<th>Currency</th>
									<th>Buy Price</th>
									<th>Sell Price</th>
								</tr>
							</thead>
							<tbody>
								{currencies.map((currency) => {
									const baseCurrency = ratesState.baseCurrency;
									let sellRate = 0;
									try {
										sellRate = getRate({
											rates,
											from: currency.code,
											to: baseCurrency,
										});
										sellRate = Big(sellRate).round(5).toString();
									} catch (err) {}

									const locale = Intl.NumberFormat().resolvedOptions().locale;
									const options = { minimumFractionDigits: 0, maximumFractionDigits: 10 };

									const sellRateFormatted = `${sellRate.toLocaleString(locale, options)} ${baseCurrency}`;
									return (
										<tr key={currency.code}>
											<td>
												<div className="currency_info">
													<div className="icon">
														<img src={currency.logo} />
													</div>
													<div className="label">
														<div className="symbol">{currency.code}</div>
														<div className="name">{currency.name}</div>
													</div>
												</div>
											</td>
											<td>{getBuyPrice(currency)}</td>
											<td>{getSellPrice(currency)}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</>
				)}
			</div>
		</>
	);
};
export default Rates;
